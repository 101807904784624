<style lang="scss">
  @import '~@assets/scss/dashboard';
  @import '~@assets/scss/_legacy_astrum';
  @import '~@assets/scss/theme-astrum';

  #astrum-home-app {
    height: 100%;
  }
</style>

<template>
  <div id="astrum-home-app">
    <svg xmlns="http://www.w3.org/2000/svg" style="position: absolute; width: 0; height: 0" aria-hidden="true">
      <defs>
        <linearGradient id="theme_svg_gradient" x1="70.69%" x2="21.188%" y1="13.678%" y2="100%">
          <stop offset="0%" stop-color="#2C81FF"/>
          <stop offset="100%" stop-color="#2C81FF"/>
        </linearGradient>
      </defs>
    </svg>

    <router-view :key="$route.name"/>
  </div>
</template>

<script>
import { ga } from '@src/plugins/ga'
import { tmr } from '@src/plugins/tmr'

import { mapActions as _mapActions, mapWritableState } from 'pinia'
import { usePlatformsStore } from '@store/platforms'

import AdvInfoModal from '@pages/Dashboard/AdvOffers/AdvInfoModal'
import AuthWarnModal from '@pages/Dashboard/AdvOffers/AuthWarnModal'

export default {
  components: {},
  computed: {},
  methods: {
    ...mapMutations('application', ['SET_IS_ASTRUM_APP']),
  },
  async created() {
    this.SET_IS_ASTRUM_APP(true)
  },
}
</script>
