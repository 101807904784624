<style scoped lang="scss">
  .astrum-home-page {

  }
</style>

<template>
  <div class="astrum-home-page">
    <sms-news-hero/>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'
import MainChannels from '@components/Home/Channels/MainChannels'
import AgeCheckModal from '@components/Home/Channels/AgeCheckModal'
import SmsNewsHero from '@components/Home/SmsNews/SmsNewsHero.vue'

const { $store } = getCurrentInstance().proxy

const advertDa = computed(() => $store.state.application.isAstrumApp)
</script>
