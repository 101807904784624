import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@state/store'
import createRouter from '@router/astrumHome'
import AstrumHomeApp from './AstrumHomeApp'

import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)

import { i18n } from '@src/localization/config'

import './_registerComponents'
import './directives'
import './_setup'

import VueTheMask from 'vue-the-mask'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueTheMask)

import GAPlugin from './plugins/ga'
import TMRPlugin from './plugins/tmr'

Vue.use(GAPlugin)
Vue.use(TMRPlugin, { astrum: true })

Vue.use(VueRouter)

// Front ENV & Sentry setup
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import frontConfig from '@services/frontConfig'
import session from '@services/session'

frontConfig
  .onReady()
  .then(async () => {
    await session.fetchSession()

    const dsn = frontConfig.getSentryDsn(process.env.VUE_APP_NAME)

    store.commit('application/SET_IS_ASTRUM_APP', true)

    if (PRODUCTION_MODE) {
      if (dsn) {
        Sentry.init({
          integrations: [new Integrations.Vue({ Vue, attachProps: true })],
          release: `vue-dashboard@${process.env.VUE_APP_PACKAGE_VERSION}`,
          environment: process.env.NODE_ENV,
          dsn,
          autoSessionTracking: false,
        })
      }
    }

    if (document.getElementById('astrum-home-app')) {
      console.log('[Astrum Home app] Creating vm...')

      new Vue({
        router: createRouter(),
        store,
        i18n,
        pinia: createPinia(),
        render: h => h(AstrumHomeApp),
      }).$mount('#astrum-home-app')
    }
  })

