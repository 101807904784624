<style lang="scss">
  .sms-news-modal {
    .success-image-wrapper {
      position: relative;

      .success-image {
        position: absolute;
        bottom: 0;
        width: 64px;
        aspect-ratio: 1 / 1;
      }
    }

    .success-message {
      max-width: 552px;

      .message-title {
        color: #EFEFEF;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.5px;
      }

      .message-text {
        margin-top: 10px;
        color: #A5A8AC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .sms-news-phone-form {
      max-width: 552px;
    }

    .sms-news-code-form {
      max-width: 552px;
    }
  }
</style>

<template>
  <modal :value="value" @input="toggleModal" class="sms-news-modal">
    <template v-if="formState === 'success'" #header-content>
      <div class="success-image-wrapper">
        <img :src="$asAppAsset('/images/advertda/modal-success-icon.png')" class="success-image"/>
      </div>
    </template>
    <template #title v-if="modalTitle">{{ modalTitle.text }}</template>
    <template #title-helper v-if="modalTitle">{{ modalTitle.helper }}</template>

    <template #body-content>
      <sms-news-phone-form v-if="formState === 'phone'" @success="onPhoneSuccess"/>
      <sms-news-code-form v-if="formState === 'code'" @success="onCodeSuccess"/>
      <div v-if="formState === 'success'" class="success-message">
        <div class="message-title">Вы подписаны!</div>
        <div class="message-text">
          Спасибо за подписку на SMS-дайджест новостей от Advertda.ru. Мы будем держать вас в курсе
          самых последних новостей!
        </div>
      </div>
    </template>
    <template #footer-content v-if="formState === 'success'">
      <btn variant="primary-action" @click="toggleModal(false)">Закрыть</btn>
    </template>
  </modal>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue'

import SmsNewsPhoneForm from '@components/Home/SmsNews/SmsNewsPhoneForm.vue'
import SmsNewsCodeForm from '@components/Home/SmsNews/SmsNewsCodeForm.vue'

defineProps({
  value: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['input'])

const toggleModal = (val) => emit('input', val)

const formState = ref('phone')

const modalTitles = {
  phone: {
    text: 'Подписаться на SMS-дайджест новостей',
    helper: 'Введите свой номер телефона, чтобы получать свежие новости от Advertda.ru',
  },
  code: {
    text: 'Подтвердите номер телефона',
    helper: 'Мы отправили SMS с кодом на ваш номер.\nПожалуйста, введите код ниже, чтобы завершить подписку.',
  },
}

const modalTitle = computed(() => modalTitles[formState.value])

const onPhoneSuccess = () => {
  formState.value = 'code'
}

const onCodeSuccess = () => {
  formState.value = 'success'
}
</script>


