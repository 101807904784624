import { render, staticRenderFns } from "./AstrumHomePage.vue?vue&type=template&id=2c0b0eed&scoped=true"
import script from "./AstrumHomePage.vue?vue&type=script&setup=true&lang=js"
export * from "./AstrumHomePage.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AstrumHomePage.vue?vue&type=style&index=0&id=2c0b0eed&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0b0eed",
  null
  
)

export default component.exports