<style lang="scss">
  .sms-news-code-form {
    .form-controls {
      padding: 32px 0;
    }

    .code-input-group {
      display: flex;
      gap: 12px;
      position: relative;

      .base-input {
        width: 42px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
      }

      .code-input {
        position: absolute;
        top: 0;
        left: 0;
        @include default-control();
        background-color: transparent;
        letter-spacing: 47px;
      }
    }
  }
</style>

<template>
  <div class="sms-news-code-form">
    <form-group
      inline
      label="Код подтверждения"
      class="phone-group">
      <div class="code-input-group">
        <base-input
          v-for="i in codeLength"
          @input="(value) => onCodeInput(i - 1, value)"
          ref="inputs"
          inputmode="numeric" pattern="[0-9]"/>
      </div>
    </form-group>
    <form-group v-if="formError">
      <info-box variant="error">{{ formError}}</info-box>
    </form-group>
    <div class="form-controls">
      <btn variant="primary-action" @click="send">Подтвердить</btn>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['success'])

const code = ref([])

const inputs = ref([])

const formError = ref(null)

const codeLength = 6

const onCodeInput = (index, v) => {
  let value = v.replace(/\D+/g, '').replace(/(\d)\d+/g, '$1')

  inputs.value[index].$el.value = value

  if (value !== v) {
    return
  }

  if (value) {
    code.value[index] = value

    if (index < codeLength - 1) {
      inputs.value[index + 1].$el.focus()
      inputs.value[index + 1].$el.select()
    }
  } else {
    code.value[index] = null

    if (index > 0) {
      inputs.value[index - 1].$el.focus()
      inputs.value[index - 1].$el.select()
    }
  }
}

const send = () => {
  api2
    .post('/newssubscriber/attempt', {
      code: code.value.join(''),
    })
    .then(() => {
      emit('success')
    })
    .catch(({ message }) => {
      formError.value = message
    })
}

</script>


