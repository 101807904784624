<style scoped lang="scss">
  .sms-news {

    .sms-news-hero {
      height: 254px;
      background-color: #115DCE;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .hero-content {
        max-width: 750px;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .hero-image{
          position: absolute;
          right: 0;
        }

        .hero-title {
          color: #FFF;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .hero-text {
          margin-top: 12px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }

        .hero-button {
          background: #FFF;
          margin-top: 40px;
          color: #115DCE;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          padding: 20px 30px 20px;
          cursor: pointer;
        }
      }
    }
  }
</style>

<template>
  <div class="sms-news">
    <div class="sms-news-hero">
      <div class="hero-content">
        <img :src="$asAppAsset('/images/advertda/sms-news.svg')" class="hero-image"/>
        <div class="hero-title">SMS-дайджест новостей</div>
        <div class="hero-text">Будь в курсе новостей Advertda.ru</div>
        <div class="hero-button" @click="showModal">Подписаться</div>
      </div>
    </div>
    <sms-news-modal v-model="modalOpened"/>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import SmsNewsModal from '@components/Home/SmsNews/SmsNewsModal.vue'

const advertDa = computed(() => $store.state.application.isAstrumApp)

const modalOpened = ref(false)

const showModal = () => modalOpened.value = true


</script>
